<script setup></script>

<template>
  <div className="bg-gray-300 text-white text-center px-4 py-4 font-bold">
    <router-link :to="{ name: 'Home' }">&lt; Back to Home</router-link>
  </div>
  <div class="container font-brother text-ch1blue">
    <div class="text-center mt-8 mb-8 pb-8 border-b border-gray-300">
      <img :src="require('@/assets/home/soth-logo-blue.png')" class="inline-block h-16 mb-4" />
      <h2 class="text-gray-500 text-lg">Cabinet of Curiosities Overview</h2>
    </div>
    <div class="font-maindisplay">
      <p>
        Below are the relevant links for the various ways you can use your Cabinet of Curiosities
        items.
      </p>
    </div>
    <div class="mt-8 pt-8 border-t border-gray-300">
      <p>
        <router-link :to="{ name: 'CocRedeem' }">
          <strong>Initial token redemption redemption</strong>
        </router-link>
      </p>
      <p>
        This is where you can redeem any Cabinet of Curiosities items for Society of the Hourglass
        main collection tokens
      </p>
      <div>
        <router-link :to="{ name: 'CocRedeem' }" class="btn">
          <strong>Redeem Cabinet of Curiosities Items ></strong>
        </router-link>
      </div>
    </div>
    <div class="mt-8 pt-8 border-t border-gray-300">
      <p>
        <strong>Assign the Book of Epochs to your characters</strong>
      </p>
      <p>
        Must do this by March 1, 2023 to guarantee their inclusion in the book - after that date the
        Book of Epochs will only be an attribute on the NFT
      </p>
      <p>
        <router-link :to="{ name: 'CocAssignmentBook' }" class="btn">
          <strong>Assign Book of Epochs ></strong>
        </router-link>
      </p>
    </div>
    <div class="mt-8 pt-8 border-t border-gray-300">
      <p>
        <strong>Assign the Auto-translator Bot to your characters</strong>
      </p>
      <p>Use your auto-translator bot token to assign an ultra-rare attribute to your NFT</p>
      <p>
        <router-link :to="{ name: 'CocAssignmentBot' }" class="btn">
          <strong>Assign Auto-translator Bot ></strong>
        </router-link>
      </p>
    </div>
  </div>
</template>
