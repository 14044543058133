<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { imagekit } from "@/utils/images";

const props = defineProps({
  url: String,
  width: String,
});

const finalUrl = computed(() => {
  return imagekit(props.url, props.width);
});
</script>

<template>
  <img :src="finalUrl" />
</template>
