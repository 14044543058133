export const imagekit = (url, width = 100) => {
  let urlStr = url;

  urlStr = urlStr?.replace(
    "https://storage.hashku.com/api/images",
    `https://ik.imagekit.io/hashku`
  );
  urlStr = urlStr?.replace("https://cdn.hashku.com", `https://ik.imagekit.io/hashku/hashkucdn`);

  if (width) {
    urlStr = `${urlStr}/tr:w-${width}`;
  }

  return urlStr;
};

// skinny m/f
// https://ik.imagekit.io/hashku/hashkucdn/storage/soth/main/744.jpg/tr:w-1500,h-1500,cm-extract,x-1250,y-000

// shady m
// https://ik.imagekit.io/hashku/hashkucdn/storage/soth/main/16.jpg/tr:w-1500,h-1500,cm-extract,x-1250,y-300

// shady f
// https://ik.imagekit.io/hashku/hashkucdn/storage/soth/main/14.jpg/tr:w-1500,h-1500,cm-extract,x-1250,y-400
