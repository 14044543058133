<script setup>
import store from "@/store";
import {
  onMounted,
  ref,
  computed,
  onUnmounted,
  reactive,
  shallowReactive,
  watch,
  markRaw,
} from "vue";
import { ethers } from "ethers";
import { DateTime } from "luxon";
import cocContract from "@/assets/coc-contract.json";
import sothContract from "@/assets/soth-contract.json";
import hashkuContract from "@/assets/hashku-contract.json";
import Navigation from "@/components/Navigation.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import ImageKit from "@/components/ImageKit.vue";

const initiateConnection = () => {
  store.dispatch("auth/connect", true);
};

const connection = computed(() => {
  return store.state.auth.connection;
});

const contracts = shallowReactive({
  // hash: null,
  soth: null,
  cabinet: null,
});

const hashesToCheck = ref([]);
const addHashToCheck = (hash) => {
  hashesToCheck.value = [...hashesToCheck.value, hash];
};
const removeHashToCheck = (hash) => {
  hashesToCheck.value = [...hashesToCheck.value.filter((h) => h !== hash)];
};

const loading = ref(false);
const getEtherscanLink = (tx) => {
  return `https://etherscan.io/tx/${tx}`;
};

// LOCAL
// const hashkuAddress = "0xC5b6BbE6262182c6D84fc84b5b85FeE645311045";
// const networkChain = 1337;

// PROD
// const hashkuAddress = "0x6de4596E80f113B6A751342842fEa6217ac98e67";
const cabinetAddress = "0xFc35EFd86e63E0Ef336f36c8ccF72fe942a58556";
const sothAddress = "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9";
const networkChain = 1;
const BOOK_CLOSED = 5;
const BOT_ACTIVE = 6;
const itemsOnContract = reactive({
  5: null,
  6: null,
});
const itemsOwned = reactive({
  5: null,
  6: null,
});

const onWrongNetwork = computed(() => {
  return parseInt(connection.value?.network?.chainId) !== parseInt(networkChain);
});

const canMint = ref(false);

const connectToContracts = async () => {
  let cab, sot;
  try {
    cab = await new ethers.Contract(cabinetAddress, cocContract.abi, connection.value.signer);
    sot = await new ethers.Contract(sothAddress, sothContract.abi, connection.value.signer);
  } catch (e) {
    console.error("could_not_connect_contracts", e);
    return;
  }

  console.info("connected to contract");
  contracts.cabinet = markRaw(cab);
  contracts.soth = markRaw(sot);
  contracts.cabinet.on("TransferSingle", (evt) => {
    console.info("transfer_single: ", { evt });
    checkContract();
  });
  contracts.cabinet.on("TransferBatch", (evt) => {
    console.info("transfer_batch: ", { evt });
    checkContract();
  });

  await checkContract();
  return true;
};

const tokensMarkedOnContract = ref([]);
const checkTokensMarked = async (tokens) => {
  if (!contracts.soth) {
    return [];
  }

  let arrayOfMarked = [];

  for (let i = 0; i < tokens.length; i++) {
    let isMarked = false;
    try {
      isMarked = await contracts.soth.hasBot(tokens[i].index);
    } catch (e) {
      console.error(e);
    }
    if (isMarked) {
      arrayOfMarked = [...arrayOfMarked, tokens[i].index];
    }
  }

  return arrayOfMarked;
};

const checkContract = async () => {
  console.info("start_read_contract");
  loading.value = true;

  if (connection.value?.address) {
    walletTokens.value = await store.dispatch("users/getTokensByWallet", connection.value?.address);
    console.info(walletTokens.value);
  }
  console.info("start_read_contract");

  if (!contracts.cabinet) {
    console.info("contract_not_set");
    return;
  }
  try {
    itemsOnContract[5] = await contracts.cabinet.items(BOOK_CLOSED);
    itemsOnContract[6] = await contracts.cabinet.items(BOT_ACTIVE);

    itemsOwned[5] = await contracts.cabinet.balanceOf(connection.value?.address, BOOK_CLOSED);
    itemsOwned[6] = await contracts.cabinet.balanceOf(connection.value?.address, BOT_ACTIVE);

    canMint.value = await contracts.soth.redemptionOpen();

    tokensMarkedOnContract.value = await checkTokensMarked(walletTokens.value);
  } catch (e) {
    console.error("could_not_read_contract", e);
    return;
  }

  loading.value = false;
  console.info("updated_from_contract");
  return;
};

const shop = async (tokenId) => {
  console.info("Start shop");
  loading.value = true;
  let shopAttempt;

  try {
    shopAttempt = await contracts.soth.useBot([tokenId]);
  } catch (e) {
    loading.value = false;

    console.error({ e });
    store.dispatch("toasts/add", {
      message: ["Transaction not completed", e?.message, e?.data?.message, e?.error?.message],
      variant: "error",
    });
    return;
  }
  console.info({ shopAttempt });
  loading.value = false;
  handleHashToCheck(shopAttempt.hash);
};

const handleHashToCheck = async (hash) => {
  addHashToCheck(hash);
  let confirmation = await connection.value.provider.waitForTransaction(hash, true);
  console.info({ confirmation });
  store.dispatch("toasts/add", {
    message: "Transaction completed!",
    variant: "success",
  });
  checkContract();
  removeHashToCheck(hash);
};

onMounted(() => {
  store.dispatch("auth/getUser");
  disconnectWallet();
});

onUnmounted(() => {
  store.dispatch("auth/clearListeners");
  disconnectWallet();
});

const pad = (id) => {
  return id.toString().padStart(2, "0");
};

watch(connection, async (newVal, oldVal) => {
  if (newVal?.network?.chainId === networkChain) {
    await connectToContracts();
  }
});

const disconnectWallet = () => {
  store.dispatch("auth/disconnect");
};

// new code
const walletTokens = ref(null);
const walletTokensEligible = computed(() => {
  if (!walletTokens.value?.length) {
    return [];
  }

  return walletTokens.value?.filter(
    (t) =>
      !t?.metadata?.find(
        (m) => m?.trait_type === "Enhancement" && m?.value === "Auto-translator Bot"
      ) && !tokensMarkedOnContract.value?.find((c) => c == t?.index)
  );
});
const walletTokensIneligible = computed(() => {
  if (!walletTokens.value) {
    return [];
  }

  return walletTokens.value?.filter(
    (t) =>
      !!(
        t?.metadata?.find(
          (m) => m?.trait_type === "Enhancement" && m?.value === "Auto-translator Bot"
        ) || tokensMarkedOnContract.value?.find((c) => c == t?.index)
      )
  );
});
</script>

<template>
  <div className="bg-gray-300 text-white text-center px-4 py-4 font-bold">
    <router-link :to="{ name: 'Coc' }">&lt; Back to Cabinet of Curiosities Overview</router-link>
  </div>

  <div class="container mb-8">
    <div class="text-ch1blue text-center text-xl font-maindisplay mb-4 mt-12">
      Cabinet of Curiosities - Assign Characters an Auto-translator Bot
    </div>
    <div class="text-gray-500 text-center mb-4" v-if="connection?.address">
      <div class="truncate">Connected with wallet: {{ connection?.address }}</div>
      <div class="text-blue cursor-pointer text-sm underline" @click="disconnectWallet">
        Connect another wallet
      </div>
    </div>
  </div>

  <div v-if="!connection?.signer" class="flex flex-col justify-center items-center my-24">
    <button class="btn bg-ch1blue text-white" @click="initiateConnection">
      Connect your wallet
    </button>
  </div>
  <div v-else>
    <div v-if="onWrongNetwork" class="container">
      <div class="text-center py-4 font-bold text-red-500 text-xl">
        Must be on the Ethereum mainnet to use Auto-translator Bot
      </div>
    </div>
    <div v-else>
      <div class="container">
        <div class="text-ch1blue italic mb-8">
          Use your redeemed Auto-translator Bot from the Cabinet of Curiosities to assign a specific
          character to have this trusty companion.<br /><br />
          Using the Auto-translator Bot for assignment will burn the token from the Cabinet of
          Curiosities collection, and will update the metadata for the character to show the bot.<br /><br />
          <strong>Note:</strong> It can take up to 24 hours for the image and metadata to update for
          a character to show "Enhancement: Auto-translator Bot"
        </div>
        <div class="font-bold text-xl">
          Auto-translator bots available for assignment:
          {{ itemsOwned[BOT_ACTIVE]?.toString() ?? 0 }}
        </div>
        <div class="mb-4 text-lg font-bold mt-4 pt-4 border-t border-gray-200">
          Characters Eligible for Auto-translator Bot:
        </div>
        <div class="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-4 mb-6">
          <div
            v-for="token in walletTokensEligible"
            :key="token.id"
            class="shadow rounded bg-white p-3 border-2"
          >
            <ImageKit :url="token.image" width="400" class="object-cover" />
            <div class="flex flex-col md:flex-row items-center justify-between mt-2">
              <div class="font-bold text-sm text-center md:mt-2 mb-2 md:mb-0">
                #{{ token.index }}
              </div>
              <div>
                <button
                  class="btn btn-sm"
                  @click="shop(token.index)"
                  :disabled="loading || parseInt(itemsOwned[BOT_ACTIVE]?.toString() ?? 0) < 1"
                >
                  Use Bot
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4 text-lg font-bold mt-4 pt-4 border-t border-gray-200">
          Characters that already have the auto-translater bot:
        </div>
        <div class="grid grid-cols-3 lg:grid-cols-5 xl:grid-cols-8 gap-4 mb-32">
          <div
            v-for="token in walletTokensIneligible"
            :key="token.id"
            class="shadow rounded bg-white p-3 border-2"
          >
            <ImageKit :url="token.image" width="400" class="object-cover" />
            <div class="flex flex-col md:flex-row items-center justify-between mt-2">
              <div class="font-bold text-sm text-center md:mt-2 mb-2 md:mb-0">
                #{{ token.index }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="fixed top-0 left-0 right-0 z-50 bottom-0 bg-black flex items-center justify-center flex-col bg-opacity-70 text-2xl font-bold text-white"
    v-if="loading"
  >
    Loading...
  </div>
</template>
