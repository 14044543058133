<script setup>
import store from "@/store";
import {
  onMounted,
  ref,
  computed,
  onUnmounted,
  reactive,
  shallowReactive,
  watch,
  markRaw,
} from "vue";
import { ethers } from "ethers";
import { DateTime } from "luxon";
import cocContract from "@/assets/coc-contract.json";
import sothContract from "@/assets/soth-contract.json";
import Navigation from "@/components/Navigation.vue";
import NavigationNew from "@/components/NavigationNew.vue";

const initiateConnection = () => {
  store.dispatch("auth/connect", true);
};

const connection = computed(() => {
  return store.state.auth.connection;
});

const contracts = shallowReactive({
  cabinet: null,
  soth: null,
});

const items = [
  {
    img: "https://cdn.hashku.com/storage/soth/cabinet/00.jpg",
    title: "Forget-Me-Dust",
    price: "0.06Ξ each",
    cartId: 0,
    quantity: "(222 available)",
    description: "Redeemable for 2 (two) NFTs from the main SotH collection",
  },
  {
    img: "https://cdn.hashku.com/storage/soth/cabinet/01.jpg",
    title: "Pocket Spectroscope",
    price: "0.12Ξ each",
    cartId: 1,
    quantity: "(166 available)",
    description: "Redeemable for 4 (four) NFTs from the main SotH collection",
  },
  {
    img: "https://cdn.hashku.com/storage/soth/cabinet/02.jpg",
    title: "The Book of Epochs",
    price: "0.17Ξ each",
    cartId: 2,
    quantity: "(99 available)",
    description:
      "Redeemable for 4 (four) NFTs from the main collection + guarantees one of your minted NFTs will appear in our illustrated seek-and-find book!",
  },
  {
    img: "https://cdn.hashku.com/storage/soth/cabinet/03.jpg",
    title: "Auto-Translator Bot",
    price: "0.17Ξ each",
    cartId: 3,
    quantity: "(99 available)",
    description:
      "Redeemable for 4 (four) NFTs from the main collection during our pre-sale period, and upgrade one of your minted NFTs with a provably rare trait—a mini robot assistant!",
  },
  {
    img: "https://cdn.hashku.com/storage/soth/cabinet/04.jpg",
    title: "Wanted Poster of Nigel",
    price: "0.18Ξ each",
    cartId: 4,
    quantity: "(99 available)",
    description:
      "Redeemable for 4 (four) NFTs from the main collection and an exclusive raffle ticket for future chances at prizes.",
  },
];

const formatTime = (time) => {
  var dt = DateTime.local();
  return DateTime.fromISO(time, { zone: "UTC" })
    .setZone(dt.zoneName)
    .toLocaleString(DateTime.DATETIME_MED);
};

const hashesToCheck = ref([]);
const addHashToCheck = (hash) => {
  hashesToCheck.value = [...hashesToCheck.value, hash];
};
const removeHashToCheck = (hash) => {
  hashesToCheck.value = [...hashesToCheck.value.filter((h) => h !== hash)];
};
const DUST = 0;
const SCOPE = 1;
const BOOK = 2;
const BOT = 3;
const POSTER = 4;
const BOOK_CLOSED = 5;
const BOT_ACTIVE = 6;

// const net = shallowReactive({
//   provider: null,
//   signer: null,
//   contract: null,
//   societyContract: null,
// });

const loading = ref(false);
const getEtherscanLink = (tx) => {
  return `https://etherscan.io/tx/${tx}`;
};

// LOCAL
// const cabinetAddress = "0x2b52a083Cf6488515df76107b324B029D51D3b92";
// const sothAddress = "0x9aff6a3D2424f302E5Ae1E174Ed6E733679eC9Ef";
// const networkChain = 1337;

// PROD
const cabinetAddress = "0xFc35EFd86e63E0Ef336f36c8ccF72fe942a58556";
const sothAddress = "0x116fDa1BF1f9C38A98Afe2A8FD67Ba5b9714dfa9";
const networkChain = 1;

const itemsOnContract = reactive({
  0: null,
  1: null,
  2: null,
  3: null,
  4: null,
  5: null,
  6: null,
});
const itemsOwned = reactive({
  0: null,
  1: null,
  2: null,
  3: null,
  4: null,
  5: null,
  6: null,
});

const sothOwned = ref(0);

const nigelRafflesOwned = ref([]);

const checkForNigels = async () => {
  if (!connection.value?.address) {
    return;
  }

  nigelRafflesOwned.value = [];

  for (let i = 11; i < 69; i++) {
    try {
      let owned = await contracts.cabinet.balanceOf(connection.value?.address, i);
      if (owned.toString() == "1") {
        nigelRafflesOwned.value = [...nigelRafflesOwned.value, i];
      }
    } catch (e) {
      console.log("not own nigel raffle", i);
    }
  }
};

const nigelsUnique = computed(() => {
  let arrayRet = [];

  nigelRafflesOwned.value.forEach((r) => {
    console.log(r);
    if (arrayRet.indexOf(r) == -1) {
      arrayRet.push(r);
    }
  });

  return arrayRet;
});

const onWrongNetwork = computed(() => {
  return parseInt(connection.value?.network?.chainId) !== parseInt(networkChain);
});

const canMint = ref(false);

const connectToContracts = async () => {
  let cab, sot;

  try {
    cab = await new ethers.Contract(cabinetAddress, cocContract.abi, connection.value.signer);
    sot = await new ethers.Contract(sothAddress, sothContract.abi, connection.value.signer);
  } catch (e) {
    console.log("could_not_connect_contracts", e);
    return;
  }
  let testItem = await cab.items(DUST);

  console.log({ testItem });

  contracts.cabinet = markRaw(cab);
  contracts.soth = markRaw(sot);
  contracts.cabinet.on("TransferSingle", (evt) => {
    console.log("transfer_single: ", { evt });
    checkContract();
  });
  contracts.cabinet.on("TransferBatch", (evt) => {
    console.log("transfer_batch: ", { evt });
    checkContract();
  });

  console.log("connected to contract");

  checkContract();
  return true;
};

const checkContract = async () => {
  console.log("start_read_contract");

  if (!contracts.cabinet) {
    console.log("contract_not_set");
    return;
  }
  try {
    itemsOnContract[0] = await contracts.cabinet.items(DUST);
    itemsOnContract[1] = await contracts.cabinet.items(SCOPE);
    itemsOnContract[2] = await contracts.cabinet.items(BOOK);
    itemsOnContract[3] = await contracts.cabinet.items(BOT);
    itemsOnContract[4] = await contracts.cabinet.items(POSTER);

    itemsOwned[0] = await contracts.cabinet.balanceOf(connection.value?.address, DUST);
    itemsOwned[1] = await contracts.cabinet.balanceOf(connection.value?.address, SCOPE);
    itemsOwned[2] = await contracts.cabinet.balanceOf(connection.value?.address, BOOK);
    itemsOwned[3] = await contracts.cabinet.balanceOf(connection.value?.address, BOT);
    itemsOwned[4] = await contracts.cabinet.balanceOf(connection.value?.address, POSTER);
    itemsOwned[5] = await contracts.cabinet.balanceOf(connection.value?.address, BOOK_CLOSED);
    itemsOwned[6] = await contracts.cabinet.balanceOf(connection.value?.address, BOT_ACTIVE);
    await checkForNigels();
    canMint.value = await contracts.soth.redemptionOpen();
    sothOwned.value = await contracts.soth.balanceOf(connection.value?.address);
  } catch (e) {
    console.log("could_not_read_contract", e);
    return;
  }
  console.log("could_read_contract");

  adjustCart();
  console.log("updated_from_contract");
  return;
};

const cart = reactive({
  0: 0,
  1: 0,
  2: 0,
  3: 0,
  4: 0,
});

const cartCompiled = computed(() => {
  const ids = [0, 1, 2, 3, 4];

  const toReturn = ids.map((id) => {
    return {
      cartId: id,
      ...items[id],
      inCart: cart[id],
      owned: itemsOwned[id],
      amountMinted: itemsOnContract[id]?.[1] ?? 0,
      amountAllowed: itemsOnContract[id]?.[2] ?? 0,
      amountLeft: itemsOnContract[id]?.[2]
        ? itemsOnContract[id]?.[2] - itemsOnContract[id]?.[1]
        : 0,
      maxMintPerAddress: itemsOnContract[id]?.[3] ?? 0,
      mainCollectionQty: itemsOnContract[id]?.[4] ?? 0,
      total: itemsOnContract[id]?.[0] ? itemsOnContract[id]?.[0] * cart[id] : 0,
    };
  });

  return toReturn;
});

const cartTotal = computed(() => {
  let itemIds = [];
  let itemQtys = [];
  cartCompiled.value.forEach((i) => {
    if (i.inCart) {
      itemIds.push(i.cartId);
      itemQtys.push(i.inCart);
    }
  });
  return {
    itemIds,
    itemQtys,
  };
});

const resetCart = () => {
  const ids = [0, 1, 2, 3, 4];
  ids.forEach((i) => {
    cart[i] = 0;
  });
};

const canAdd = (id) => {
  const ids = [0, 1, 2, 3, 4];

  if (itemsOwned[id]?.toNumber() < 1 + cart[id]) {
    return false;
  }

  return true;
};

const canCheckout = () => {
  if (!canMint.value) {
    return false;
  }
  const ids = [0, 1, 2, 3, 4];
  let totalAdded = 0;
  ids.forEach((i) => {
    totalAdded += cart[i];

    if (itemsOnContract[i]?.[2]?.toNumber() < 1) {
      return false;
    }

    if (cart[i] + 1 > itemsOnContract[i]?.[2].toNumber() - itemsOnContract[i]?.[1].toNumber()) {
      return false;
    }
  });

  if (totalAdded === 0) {
    return false;
  }

  return true;
};

const addToCart = (id) => {
  if (canAdd(id)) {
    cart[id]++;
  }
};

const removeFromCart = (id) => {
  cart[id]--;
};

const adjustCart = () => {
  cartCompiled.value.forEach((itm, i) => {
    if (itm.amountLeft < cart[itm.cartId]) {
      cart[itm.cartId] = itm.amountLeft;
    }
  });
};

const lockForShop = ref(false);

const shop = async () => {
  console.log("Start shop");
  lockForShop.value = true;
  let shopAttempt;

  try {
    shopAttempt = await contracts.soth.redeem(cartTotal.value.itemIds, cartTotal.value.itemQtys);
  } catch (e) {
    lockForShop.value = false;

    console.log({ e });
    store.dispatch("toasts/add", {
      message: ["Transaction not completed", e?.message, e?.data?.message, e?.error?.message],
      variant: "error",
    });
    return;
  }
  console.log({ shopAttempt });
  lockForShop.value = false;
  handleHashToCheck(shopAttempt.hash);
  resetCart();
};

const handleHashToCheck = async (hash) => {
  addHashToCheck(hash);
  let confirmation = await connection.value.provider.waitForTransaction(hash, true);
  console.log({ confirmation });
  store.dispatch("toasts/add", {
    message: "Transaction completed!",
    variant: "success",
  });
  checkContract();
  removeHashToCheck(hash);
};

onMounted(() => {
  store.dispatch("auth/getUser");
  disconnectWallet();
});

onUnmounted(() => {
  store.dispatch("auth/clearListeners");
  disconnectWallet();
});

const pad = (id) => {
  return id.toString().padStart(2, "0");
};

watch(connection, (newVal, oldVal) => {
  if (newVal?.network?.chainId === networkChain) {
    connectToContracts();
  }
});

const disconnectWallet = () => {
  store.dispatch("auth/disconnect");
};
</script>

<template>
  <!-- <NavigationNew /> -->
  <div className="bg-gray-300 text-white text-center px-4 py-4 font-bold">
    <router-link :to="{ name: 'Coc' }">&lt; Back to Cabinet of Curiosities Overview</router-link>
  </div>
  <div class="container">
    <div class="text-ch1blue text-center text-xl font-maindisplay mb-4 mt-12">
      Cabinet of Curiosities Redemption
    </div>
    <div class="text-gray-500 text-center mb-4" v-if="connection?.address">
      <div class="truncate">Connected with wallet: {{ connection?.address }}</div>
      <div class="text-blue cursor-pointer text-sm underline" @click="disconnectWallet">
        Connect another wallet
      </div>
    </div>
  </div>

  <div v-if="!connection?.signer" class="flex flex-col justify-center items-center my-24">
    <button class="btn bg-ch1blue text-white" @click="initiateConnection">
      Connect your wallet
    </button>
  </div>
  <div v-else>
    <div v-if="onWrongNetwork" class="container">
      <div class="text-center py-4">Must be on the Ethereum Mainnet</div>
    </div>
    <div v-else>
      <div class="container">
        <div class="py-4">
          <div class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-4">
            <div class="col-span-1 md:col-span-2 lg:col-span-3">
              <div
                v-for="item in cartCompiled"
                :key="item.cartId"
                class="mb-4 shadow rounded bg-white p-4"
              >
                <div class="flex">
                  <div class="flex-shrink-0 mr-4">
                    <img :src="item.img" class="w-16 md:w-24 lg:w-28" />
                  </div>
                  <div class="mr-4 flex-grow">
                    <div class="text-xs text-gray-400">Cabinet of Curiosities</div>
                    <div class="text-md lg:text-lg font-semibold mb-4">{{ item.title }}</div>
                    <div class="text-xs text-gray-400">About this item</div>
                    <div class="text-xs" v-html="item.description"></div>
                  </div>
                  <div class="flex flex-shrink-0 flex-col items-end">
                    <div class="flex flex-col">
                      <button
                        class="btn btn-outline btn-sm w-20"
                        @click="addToCart(item.cartId)"
                        :disabled="!canAdd(item.cartId) || lockForShop"
                      >
                        Add
                      </button>
                      <button
                        class="btn btn-outline btn-sm w-20 mt-2"
                        @click="removeFromCart(item.cartId) || lockForShop"
                        v-if="item.inCart"
                        :disabled="lockForShop"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="text-sm font-bold mt-2 rounded bg-green-400 py-1 px-2 text-white"
                  v-if="itemsOwned[item.cartId] > 0"
                >
                  You own {{ itemsOwned[item.cartId] }} of these!
                </div>
              </div>
            </div>
            <div class="col-span-1 md:col-span-2">
              <div class="bg-gray-100 p-4 shadow rounded mb-4">
                <div class="flex justify-between items-center mb-2">
                  <div class="font-bold">Cost for redemption is only gas</div>
                </div>
                <div v-for="item in cartCompiled" :key="item.cartId">
                  <div class="mb-1 flex justify-between italic text-sm" v-if="item.inCart">
                    <div>{{ item.title }}</div>
                    <div>{{ item.inCart }}</div>
                  </div>
                </div>
              </div>
              <div class="w-full flex flex-row">
                <div class="w-full flex">
                  <button
                    class="btn btn-lg flex-grow"
                    :disabled="!canCheckout() || lockForShop"
                    @click="shop"
                  >
                    Redeem Items Now
                  </button>
                </div>
              </div>
              <div class="mt-8 pt-8 border-t border-t-gray-500" v-if="hashesToCheck.length">
                <div
                  v-for="(hash, h) in hashesToCheck"
                  :key="h"
                  class="flex item-center text-gray-700 p-2 bg-gray-100 rounded shadow"
                >
                  <div class="flex flex-col justify-center mr-4">Loading...</div>
                  <div>
                    <div class="font-semibold text-sm">Mint in progress:</div>
                    <div>
                      <a
                        :href="getEtherscanLink(hash)"
                        rel="noref noopener"
                        target="_blank"
                        class="text-blue underline text-sm"
                      >
                        See pending transaction on Etherscan
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="py-2 border-t border-ch1blue text-ch1blue w-full mt-6"
                v-if="sothOwned && sothOwned.toString() !== '0'"
              >
                Numer of main collection tokens owned: <strong>{{ sothOwned }}</strong>
              </div>

              <div class="mt-6 grid grid-cols-2 gap-4">
                <div class="" v-if="itemsOwned[5] && itemsOwned[5]?.toString() !== '0'">
                  <div class="text-gray font-bold text-xs mb-2">Book of Epochs (Closed)</div>
                  <img src="https://cdn.hashku.com/storage/soth/cabinet/05.jpg" />
                  <div class="text-sm font-bold mt-2 rounded bg-green-400 py-1 px-2 text-white">
                    You own {{ itemsOwned[5]?.toString() }} of these
                  </div>
                </div>
                <div class="" v-if="itemsOwned[6] && itemsOwned[6]?.toString() !== '0'">
                  <div class="text-gray font-bold text-xs mb-2">Auto-Translator Bot (Active)</div>
                  <img src="https://cdn.hashku.com/storage/soth/cabinet/06.jpg" />
                  <div class="text-sm font-bold mt-2 rounded bg-green-400 py-1 px-2 text-white">
                    You own {{ itemsOwned[6]?.toString() }} of these
                  </div>
                </div>
                <div class="" v-for="(nigel, n) in nigelsUnique" :key="n">
                  <div class="text-gray font-bold text-xs mb-2">
                    Nigel Raffle Ticket #{{ nigel - 10 }}
                  </div>
                  <img :src="`https://cdn.hashku.com/storage/soth/cabinet/${pad(nigel)}.jpg`" />
                  <div class="text-sm font-bold mt-2 rounded bg-green-400 py-1 px-2 text-white">
                    You own 1 of these
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="fixed top-0 left-0 right-0 z-50 bottom-0 bg-gray-600 flex items-center justify-center flex-col opacity-10"
    v-if="lockForShop"
  >
    Loading...
  </div>
</template>
